import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OpenInNewOutlined from '@material-ui/icons/OpenInNewOutlined';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useApi } from '../../providers/ApiProvider';
import { useApp } from '../../providers/AppProvider';
import { Translate } from '../../i18n/Translate';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const IconLink = styled.a`
  color: ${(props) => props.theme.main};
  text-decoration: none;
`;

const OpenExternalIcon = styled(OpenInNewOutlined)`
  color: ${(props) => props.theme.main};
`;

export const ProductsTable = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const [pending, setPending] = useState(true);
  const { get } = useApi();
  const { shopID } = useApp();

  useEffect(() => {
    get(`shop/${shopID}/products`)
      .then(function (response) {
        setProducts(response.products);
        setPending(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setPending(false);
      });
  }, [get, shopID]);

  function showProductDetails(productID) {
    history.push(`/products/${productID}`);
  }

  return pending ? (
    <CircularProgress></CircularProgress>
  ) : (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate text="product.table.id" />
            </TableCell>
            <TableCell>
              <Translate text="product.table.product" />
            </TableCell>
            <TableCell align="right">
              <Translate text="product.table.link" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow
              key={product.id}
              onClick={() => showProductDetails(product.id)}
            >
              <TableCell>{product.id}</TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell align="right">
                <IconLink
                  onClick={(event) => event.stopPropagation()}
                  href={product.permalink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <OpenExternalIcon />
                </IconLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
