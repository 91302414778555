import React from 'react';
import styled from 'styled-components';
import { useApp } from '../../providers/AppProvider';

const Container = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
`;

const IndicatorIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: green;
  margin-right: 4px;
`;

const ConnectionUrl = styled.a`
  font-weight: 600;
  font-size: 0.85em;
  color: ${(props) => props.theme.main};
  text-decoration: none;
`;

export const ConnectionState = () => {
  const app = useApp();

  return app.shop.shop ? (
    <Container>
      <IndicatorIcon></IndicatorIcon>
      <ConnectionUrl href={app.shop.shop}>{app.shop.shop}</ConnectionUrl>
    </Container>
  ) : null;
};
