import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import { Translate } from '../../i18n/Translate';
import { Button } from '../../ui/button/Button';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClipboardIcon from '@material-ui/icons/FileCopyOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const Layout = styled.div`
  margin-top: 16px;
`;

const ActionButton = styled(Button)`
  margin-top: 16px;
`;

const Form = styled.form`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getSetupState = (setup) => {
  if (setup == null) {
    return 0;
  }
  if (
    setup.delivery_enabled &&
    setup.webhooks_setup &&
    setup.installation_completed
  ) {
    return 4;
  } else if (setup.installation_completed && setup.webhooks_setup) {
    return 2;
  } else if (setup.installation_completed) {
    return 1;
  }
};

export const SetupSteps = ({
  setup,
  onInstallationStarted,
  installationUrl,
}) => {
  const history = useHistory();
  const [shopUrl, setShopUrl] = useState('https://');
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setupState = getSetupState(setup);

  const onInstallFormSubmitted = (event) => {
    event.preventDefault();
    onInstallationStarted(shopUrl);
  };

  const handleChange = (event) => {
    setShopUrl(event.target.value);
  };

  return (
    <Layout>
      <Stepper activeStep={setupState} orientation="vertical">
        <Step>
          <StepLabel>{<Translate text="setup.installation.title" />}</StepLabel>
          <StepContent>
            <Typography variant="body2">
              <Translate text="setup.installation.info" />
            </Typography>
            <Form onSubmit={onInstallFormSubmitted}>
              {installationUrl ? (
                <CopyToClipboard text={installationUrl} onCopy={handleClick}>
                  <Typography variant="body2">
                    <ClipboardIcon />
                    <Translate text="setup.installation.copy" />

                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert severity="success">
                        <Translate text="setup.installation.copied_to_clipboard" />
                      </Alert>
                    </Snackbar>
                  </Typography>
                </CopyToClipboard>
              ) : (
                <Fragment>
                  <TextField
                    value={shopUrl}
                    onChange={handleChange}
                    id="shop_url"
                    label={<Translate text="setup.installation.shop_url" />}
                    variant="outlined"
                    size="small"
                    required
                  />
                  <ActionButton
                    size="small"
                    variant="outlined"
                    color="primary"
                    text="setup.installation.action"
                    type="submit"
                  />
                </Fragment>
              )}
            </Form>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{<Translate text="setup.webhook.title" />}</StepLabel>
          <StepContent>
            <Typography variant="body2">
              <Translate text="setup.webhook.info" />
            </Typography>
            <ActionButton
              size="small"
              variant="outlined"
              color="primary"
              text="setup.webhook.action"
              onClick={() => history.push('/webhooks')}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{<Translate text="setup.delivery.title" />}</StepLabel>
          <StepContent>
            <Typography variant="body2">
              <Translate text="setup.delivery.info" />
            </Typography>
            <ActionButton
              size="small"
              variant="outlined"
              color="primary"
              text="setup.delivery.action"
              onClick={() => history.push('/products')}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{<Translate text="setup.completed.title" />}</StepLabel>
        </Step>
      </Stepper>
    </Layout>
  );
};
