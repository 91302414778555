import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Translate } from '../../../i18n/Translate';
import { useApi } from '../../../providers/ApiProvider';
import { useApp } from '../../../providers/AppProvider';
import { WebhookBreadcrumbs } from '../WebhookBreadcrumbs';
import { WebhookAddNew } from './WebhookAddNew';

const webhookData = {
  created: {
    topic: 'order.created',
    name: 'Order created (deliver mobile tickets)',
  },
  updated: {
    topic: 'order.updated',
    name: 'Order updated (update tickets)',
  },
  deleted: {
    topic: 'order.deleted',
    name: 'Order deleted (cancel tickets)',
  },
  productCreated: {
    topic: 'product.created',
    name: 'Product created',
  },
  productUpdated: {
    topic: 'product.updated',
    name: 'Product updated',
  },
  productDeleted: {
    topic: 'product.deleted',
    name: 'Product deleted',
  },
};

export const WebhookAddNewContainer = () => {
  const history = useHistory();
  const { post } = useApi();
  const { shopID } = useApp();
  const [pending, setPending] = useState(false);

  const onBackClicked = () => {
    history.push('/webhooks');
  };

  const onDeliveryTypeSelected = async (type) => {
    try {
      setPending(true);
      const webhook = webhookData[type];
      await post(`shop/${shopID}/webhooks`, webhook);
      onBackClicked();
    } catch (error) {
      console.log(error);
      setPending(false);
      // TODO
    }
  };

  return (
    <div>
      <WebhookBreadcrumbs
        webhookName={<Translate text="webhook.new.title" />}
      />
      <WebhookAddNew
        onCancelClick={onBackClicked}
        onDeliveryTypeSelected={onDeliveryTypeSelected}
        pending={pending}
      />
    </div>
  );
};
