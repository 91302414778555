import React from 'react';
import styled from 'styled-components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Translate } from '../../i18n/Translate';

const Layout = styled.div`
  padding-bottom: 16px;
`;

const LinkBehavior = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} to="product" {...props} />
));

export const ProductBreadcrumbs = ({ productName }) => {
  return (
    <Layout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={LinkBehavior} to="/products">
          <Translate text="product.title" />
        </Link>
        {productName ? <Link color="inherit">{productName}</Link> : null}
      </Breadcrumbs>
    </Layout>
  );
};
