import React from 'react';
import styled from 'styled-components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Translate } from '../../i18n/Translate';

const Layout = styled.div`
  padding-bottom: 16px;
`;

const LinkBehavior = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} to="product" {...props} />
));

export const WebhookBreadcrumbs = ({ webhookName }) => {
  return (
    <Layout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={LinkBehavior} to="/webhooks">
          <Translate text="webhook.title" />
        </Link>
        {webhookName ? <Link color="inherit">{webhookName}</Link> : null}
      </Breadcrumbs>
    </Layout>
  );
};
