import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory, useLocation } from 'react-router-dom';
import { Translate } from '../../i18n/Translate';

const allowedPathnames = ['/', '/products', '/webhooks'];

export const TabNavigation = () => {
  const [value, setValue] = React.useState('/');
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  useEffect(() => {
    if (allowedPathnames.includes(location.pathname)) {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="simple tabs example"
      indicatorColor="primary"
    >
      <Tab label={<Translate text="navigation.tab.setup" />} value="/" />
      <Tab
        label={<Translate text="navigation.tab.product" />}
        value="/products"
      />
      <Tab
        label={<Translate text="navigation.tab.webhook" />}
        value="/webhooks"
      />
    </Tabs>
  );
};
