import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApp } from './AppProvider';

const ApiContext = React.createContext({});

const ApiProvider = ({ children, ...props }) => {
  const app = useApp();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${app.token}`;
    setInitialized(true);
  }, [app.token]);

  async function get(path) {
    try {
      const { data } = await axios.get(`${app.apiBase}/${path}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function put(path, body) {
    try {
      const data = await axios.put(`${app.apiBase}/${path}`, body);
      return data.data ? data.data : null;
    } catch (error) {
      console.log(error);
    }
  }

  async function post(path, body) {
    try {
      const data = await axios.post(`${app.apiBase}/${path}`, body);
      return data.data ? data.data : null;
    } catch (error) {
      console.log(error);
    }
  }

  async function del(path) {
    try {
      await axios.delete(`${app.apiBase}/${path}`);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ApiContext.Provider
      value={{
        get,
        put,
        post,
        del,
        initialized,
      }}
      {...props}
    >
      {app.initialized ? children : null}
    </ApiContext.Provider>
  );
};
const useApi = () => React.useContext(ApiContext);

export { ApiProvider, useApi };
