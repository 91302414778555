import { muiTheme } from './ui/theme/muiTheme';
import { theme } from './ui/theme/theme';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from '@material-ui/styles';
import styled, { ThemeProvider } from 'styled-components';
import { ConnectionState } from './components/connection/ConnectionState';
import { TabNavigation } from './components/tabNavigation/TabNavigation';
import { MemoryRouter } from 'react-router-dom';
import { AppContent } from './components/main/AppContent';
import { AppProvider } from './providers/AppProvider';
import { ApiProvider } from './providers/ApiProvider';
import { IntlProvider } from 'react-intl';
import de from './i18n/locales/de.json';
import en from './i18n/locales/en.json';
import queryString from 'query-string';
import { Language } from './ui/Language';

const AppContainer = styled.div``;

const AppHeader = styled.div`
  padding: 16px 16px 0px 16px;
  background-color: white;
`;

const AppLogo = styled.img`
  width: 200px;
`;

const App = () => {
  const locale =
    queryString.parse(window.location.search)?.locale || Language.EN;
  const messages = locale === Language.DE ? de : en;

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <IntlProvider
            defaultLocale={Language.EN}
            messages={messages}
            locale={locale}
          >
            <MemoryRouter>
              <AppProvider>
                <AppContainer>
                  <ApiProvider>
                    <AppHeader>
                      <AppLogo
                        src={process.env.PUBLIC_URL + '/logo-woocommerce.svg'}
                        alt="WooCommerce Logo"
                      />
                      <ConnectionState />
                      <TabNavigation />
                    </AppHeader>
                    <AppContent />
                  </ApiProvider>
                </AppContainer>
              </AppProvider>
            </MemoryRouter>
          </IntlProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default App;
