import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useApi } from '../../../providers/ApiProvider';
import { useApp } from '../../../providers/AppProvider';
import { WebhookBreadcrumbs } from '../WebhookBreadcrumbs';
import { WebhookDetails } from './WebhookDetails';

export const WebhookDetailsContainer = () => {
  const match = useRouteMatch();
  const [webhook, setWebhook] = useState({});
  const [pending, setPending] = useState(true);
  const [deletePending, setDeletePending] = useState(false);
  const history = useHistory();
  const { get, del } = useApi();
  const { shopID } = useApp();

  useEffect(() => {
    const webhookID = match.params.webhookID;
    get(`shop/${shopID}/webhooks/${webhookID}`).then((webhook) => {
      setWebhook(webhook);
      setPending(false);
    });
  }, [match.params.webhookID, get, shopID]);

  const onDeleteClick = async () => {
    try {
      setDeletePending(true);
      await del(`shop/${shopID}/webhooks/${match.params.webhookID}`);
      history.replace('/webhooks');
    } catch (error) {
      // TODO
      setDeletePending(true);
    }
  };

  return pending ? (
    <CircularProgress />
  ) : (
    <div>
      <WebhookBreadcrumbs webhookName={webhook.name} />
      {webhook ? (
        <WebhookDetails
          webhook={webhook}
          onDeleteClick={onDeleteClick}
          deletePending={deletePending}
        ></WebhookDetails>
      ) : null}
    </div>
  );
};
