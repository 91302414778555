import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SendIcon from '@material-ui/icons/SendOutlined';
import UpdateIcon from '@material-ui/icons/UpdateOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Translate } from '../../../i18n/Translate';
import { Button } from '../../../ui/button/Button';

const StyledPaper = styled(Paper)`
  position: relative;
`;

const Layout = styled.div`
  padding: 16px;
  ${(props) => (props.pending ? 'filter: blur(3px)' : null)}
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  bottom: 0;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const WebhookAddNew = ({
  onCancelClick,
  onDeliveryTypeSelected,
  pending,
}) => {
  const handleListItemClick = (type) => {
    onDeliveryTypeSelected(type);
  };

  return (
    <div>
      <StyledPaper>
        <Layout pending={pending}>
          <Typography variant="subtitle1">
            <Translate text="webhook.new.title" />
          </Typography>
          <List>
            <ListItem button onClick={() => handleListItemClick('created')}>
              <ListItemAvatar>
                <Avatar>
                  <SendIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Translate text="webhook.order_created.title" />}
                secondary={<Translate text="webhook.order_created.info" />}
              />
            </ListItem>
            <ListItem button onClick={() => handleListItemClick('updated')}>
              <ListItemAvatar>
                <Avatar>
                  <UpdateIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Translate text="webhook.order_updated.title" />}
                secondary={<Translate text="webhook.order_updated.info" />}
              />
            </ListItem>
            <ListItem button onClick={() => handleListItemClick('deleted')}>
              <ListItemAvatar>
                <Avatar>
                  <CancelIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Translate text="webhook.order_deleted.title" />}
                secondary={<Translate text="webhook.order_deleted.info" />}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleListItemClick('productCreated')}
            >
              <ListItemAvatar>
                <Avatar>
                  <SendIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Translate text="webhook.product_created.title" />}
                secondary={<Translate text="webhook.product_created.info" />}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleListItemClick('productUpdated')}
            >
              <ListItemAvatar>
                <Avatar>
                  <UpdateIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Translate text="webhook.product_updated.title" />}
                secondary={<Translate text="webhook.product_updated.info" />}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleListItemClick('productDeleted')}
            >
              <ListItemAvatar>
                <Avatar>
                  <CancelIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Translate text="webhook.product_deleted.title" />}
                secondary={<Translate text="webhook.product_deleted.info" />}
              />
            </ListItem>
          </List>
          <Button
            color="secondary"
            variant="text"
            onClick={onCancelClick}
            text="action.cancel"
          />
        </Layout>
        {pending ? (
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        ) : null}
      </StyledPaper>
    </div>
  );
};
