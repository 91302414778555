import React from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Translate } from '../../../i18n/Translate';

const Layout = styled.div`
  padding: 16px;
`;

const HelperText = styled(FormHelperText)`
  margin-bottom: 16px;
`;

export const ProductDetailsSettings = ({
  productSettings,
  onSettingsChanged,
}) => {
  const handleChange = (event) => {
    onSettingsChanged({
      ...productSettings,
      delivery_enabled: event.target.checked,
    });
  };

  const handleSettingsChange = (event) => {
    onSettingsChanged({
      ...productSettings,
      settings: {
        ...productSettings.settings,
        [event.target.name]: event.target.checked,
      },
    });
  };

  return (
    <Paper>
      <Layout>
        <Typography variant="subtitle1">
          <Translate text="product.settings.title" />
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={productSettings.delivery_enabled}
                onChange={handleChange}
                name="delivery_enabled"
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                <Translate text="product.settings.delivery" />
              </Typography>
            }
          />
          <HelperText>
            <Translate text="product.settings.delivery.info" />
          </HelperText>
          <FormControlLabel
            control={
              <Switch
                checked={productSettings.settings.do_personalization}
                onChange={handleSettingsChange}
                name="do_personalization"
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                <Translate text="product.settings.personalization" />
              </Typography>
            }
          />
          <HelperText>
            <Translate text="product.settings.personalization.info" />
          </HelperText>
        </FormGroup>
      </Layout>
    </Paper>
  );
};
