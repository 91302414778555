import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import axios from 'axios';

const AppContext = React.createContext({});

const AppProvider = ({ children, ...props }) => {
  const apiBase = process.env.REACT_APP_KEYPER_API_BASE;
  const parsed = queryString.parse(window.location.search);

  const [token, setToken] = useState(parsed.token);
  const [shop, setShop] = useState({});
  const [initialized, setInitialized] = useState(false);
  const redirectUri = parsed.redirectUri;

  useEffect(() => {
    if (token === parsed.token) {
      axios
        .post(`${apiBase}/token/shop`, null, {
          header: { Authorizaton: `Bearer ${token}` },
        })
        .then(({ data }) => {
          if (data.token) {
            setToken(data.token);
          }
          if (data.shop) {
            setShop(data.shop);
          }
          setInitialized(true);
        });
    }
  }, [apiBase, token, parsed.token]);

  return (
    <AppContext.Provider
      value={{
        apiBase,
        redirectUri,
        token,
        shop,
        shopID: shop.shop_id,
        initialized,
      }}
      {...props}
    >
      {children}
    </AppContext.Provider>
  );
};
const useApp = () => React.useContext(AppContext);

export { AppProvider, useApp };
