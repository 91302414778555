import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import PauseIcon from '@material-ui/icons/PauseCircleFilledOutlined';
import BlockIcon from '@material-ui/icons/BlockOutlined';
import { Translate } from '../../i18n/Translate';

const Layout = styled.div`
  display: flex;
  align-items: center;
`;

const Active = styled(CheckIcon)`
  color: green;
`;

const Paused = styled(PauseIcon)`
  color: yellow;
`;

const Disabled = styled(BlockIcon)`
  color: grey;
`;

const Text = styled.span`
  padding-left: 8px;
`;

export const WebhookStatus = ({ status, showText }) => {
  return (
    <Layout>
      {status === 'active' ? <Active /> : null}
      {status === 'paused' ? <Paused /> : null}
      {status === 'disabled' ? <Disabled /> : null}
      {showText ? (
        <Text>
          <Translate text={`webhook.state.${status}`} />
        </Text>
      ) : null}
    </Layout>
  );
};
