import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Translate } from '../../i18n/Translate';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

export const Button = ({
  color,
  variant,
  loading,
  onClick,
  text,
  className,
  size = 'medium',
  type,
}) => (
  <Wrapper className={className}>
    <MuiButton
      variant={variant}
      color={color}
      disabled={loading}
      onClick={onClick}
      size={size}
      type={type}
    >
      <Translate text={text} />
    </MuiButton>
    {loading && <StyledCircularProgress size={24} color={color} />}
  </Wrapper>
);
