import React from 'react';
import { ProductBreadcrumbs } from './ProductBreadcrumbs';
import { ProductsTable } from './ProductsTable';

export const Products = () => (
  <div>
    <ProductBreadcrumbs />
    <ProductsTable></ProductsTable>
  </div>
);
