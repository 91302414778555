import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Translate } from '../../i18n/Translate';
import { SetupSteps } from './SetupSteps';
import { useApi } from '../../providers/ApiProvider';
import { useApp } from '../../providers/AppProvider';

export const SetupContainer = () => {
  const [setup, setSetup] = useState(null);
  const [pending, setPending] = useState(true);
  const [installationUrl, setInstallationUrl] = useState('');
  const { get, post, initialized } = useApi();
  const { shopID, redirectUri } = useApp();

  useEffect(() => {
    if (initialized) {
      get(`shop/${shopID}/setup`).then((_setup) => {
        setSetup(_setup);
        setPending(false);
      });
    }
  }, [get, shopID, initialized]);

  const onInstallationStarted = async (shop_url) => {
    try {
      const response = await post(`installation`, {
        shop_url,
        return_url: redirectUri,
      });
      setInstallationUrl(response.installation_url);
    } catch (error) {
      console.log(error);
      // TODO
    }
  };

  return (
    <div>
      <Typography variant="body1" color="primary">
        <Translate text="setup.welcome" />
      </Typography>
      {pending ? null : (
        <SetupSteps
          setup={setup}
          onInstallationStarted={onInstallationStarted}
          installationUrl={installationUrl}
        />
      )}
    </div>
  );
};
