import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { WebhookStatus } from '../WebookStatus';
import { Button } from '../../../ui/button/Button';
import { Translate } from '../../../i18n/Translate';

const Layout = styled.div`
  padding: 16px;
`;

const Row = styled.div`
  margin-bottom: 16px;
`;

export const WebhookDetails = ({ webhook, onDeleteClick, deletePending }) => {
  return (
    <Paper>
      <Layout>
        <Row>
          <Typography variant="body2">
            <Translate text="webhook.details.name" />
          </Typography>
          <Typography variant="body1">{webhook.name}</Typography>
        </Row>
        <Row>
          <Typography variant="body2">
            <Translate text="webhook.details.topic" />
          </Typography>
          <Typography variant="body1">{webhook.topic}</Typography>
        </Row>
        <Row>
          <Typography variant="body2">
            <Translate text="webhook.details.state" />
          </Typography>
          <Typography variant="body1">
            <WebhookStatus status={webhook.status} showText />
          </Typography>
        </Row>
        <Row>
          <Typography variant="body2">
            <Translate text="webhook.details.delivery_url" />
          </Typography>
          <Typography variant="body1">{webhook.delivery_url}</Typography>
        </Row>
        <Row>
          <Typography variant="body2">
            <Translate text="webhook.details.created_at" />
          </Typography>
          <Typography variant="body1">{webhook.date_created_gmt}</Typography>
        </Row>
        <Button
          color="secondary"
          variant="outlined"
          onClick={onDeleteClick}
          text="action.delete"
          loading={deletePending}
        />
      </Layout>
    </Paper>
  );
};
