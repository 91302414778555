import React from 'react';
import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import { useHistory } from 'react-router-dom';

const PositionedFab = styled(Fab)`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

export const AddWebhookFab = () => {
  const theme = useTheme();
  const history = useHistory();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const onAddWebhookClicked = () => {
    history.push('/webhooks/add');
  };

  return (
    <Zoom
      in={true}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${transitionDuration.enter}ms`,
      }}
      unmountOnExit
    >
      <PositionedFab
        color="primary"
        aria-label="add"
        onClick={onAddWebhookClicked}
      >
        <AddIcon />
      </PositionedFab>
    </Zoom>
  );
};
