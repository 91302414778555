import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Products } from '../products/Products';
import { ProductDetails } from '../products/details/ProductDetails';
import { Webhooks } from '../webhooks/Webhooks';
import { WebhookDetailsContainer } from '../webhooks/details/WebhookDetailsContainer';
import { WebhookAddNewContainer } from '../webhooks/add/WebhookAddNewContainer';
import { SetupContainer } from '../setup/SetupContainer';

const Layout = styled.div`
  padding: 16px;
  border-top: 1px solid #c9cccf;
`;

export const AppContent = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/webhooks/add" component={WebhookAddNewContainer}></Route>
        <Route
          path="/webhooks/:webhookID"
          component={WebhookDetailsContainer}
        ></Route>
        <Route path="/webhooks" component={Webhooks}></Route>
        <Route path="/products/:productID" component={ProductDetails} />
        <Route path="/products" component={Products} />
        <Route path="/" component={SetupContainer} />
      </Switch>
    </Layout>
  );
};
