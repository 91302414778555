import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useApi } from '../../../providers/ApiProvider';
import { useApp } from '../../../providers/AppProvider';
import { ProductBreadcrumbs } from '../ProductBreadcrumbs';
import { ProductDetailsSettings } from './ProductDetailsSettings';

export const ProductDetails = () => {
  const match = useRouteMatch();
  const [product, setProduct] = useState({});
  const [settings, setSettings] = useState(null);
  const [pending, setPending] = useState(true);
  const { get, put } = useApi();
  const { shopID } = useApp();

  useEffect(() => {
    const productID = match.params.productID;
    Promise.all([
      get(`shop/${shopID}/products/${productID}`),
      get(`shop/${shopID}/products/${productID}/settings`),
    ]).then(([_product, _settings]) => {
      setProduct(_product);
      setSettings(_settings);
      setPending(false);
    });
  }, [match.params.productID, get, shopID]);

  async function onSettingsChanged(settings) {
    try {
      setSettings(settings);
      const response = await put(
        `shop/${shopID}/products/${match.params.productID}/settings`,
        { ...settings },
      );
      setSettings(response);
    } catch (error) {
      // TODO
    }
  }

  return pending ? (
    <CircularProgress />
  ) : (
    <div>
      <ProductBreadcrumbs productName={product.name} />
      {settings ? (
        <ProductDetailsSettings
          productSettings={settings}
          onSettingsChanged={onSettingsChanged}
        ></ProductDetailsSettings>
      ) : null}
    </div>
  );
};
