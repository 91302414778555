import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from './colors';

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.main,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
});
