import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useApi } from '../../providers/ApiProvider';
import { useApp } from '../../providers/AppProvider';
import { WebhookStatus } from './WebookStatus';
import { useHistory } from 'react-router-dom';
import { Translate } from '../../i18n/Translate';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const WebhooksTable = () => {
  const classes = useStyles();
  const [webhooks, setWebhooks] = useState([]);
  const [pending, setPending] = useState(true);
  const { get } = useApi();
  const history = useHistory();
  const { shopID } = useApp();

  useEffect(() => {
    get(`shop/${shopID}/webhooks`)
      .then(function (response) {
        setWebhooks(response.webhooks);
        setPending(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setPending(false);
      });
  }, [get, shopID]);

  function showDetails(webhookID) {
    history.push(`/webhooks/${webhookID}`);
  }

  return pending ? (
    <CircularProgress></CircularProgress>
  ) : (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="webhook table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate text="webhook.table.id" />
            </TableCell>
            <TableCell>
              <Translate text="webhook.table.name" />
            </TableCell>
            <TableCell>
              <Translate text="webhook.table.topic" />
            </TableCell>
            <TableCell>
              <Translate text="webhook.table.state" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {webhooks.map((webhook) => (
            <TableRow key={webhook.id} onClick={() => showDetails(webhook.id)}>
              <TableCell>{webhook.id}</TableCell>
              <TableCell>{webhook.name}</TableCell>
              <TableCell>{webhook.topic}</TableCell>
              <TableCell>
                <WebhookStatus status={webhook.status} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
