import React from 'react';
import { AddWebhookFab } from './add/AddWebhookFab';
import { WebhookBreadcrumbs } from './WebhookBreadcrumbs';
import { WebhooksTable } from './WebhooksTable';

export const Webhooks = () => (
  <div>
    <WebhookBreadcrumbs />
    <WebhooksTable></WebhooksTable>
    <AddWebhookFab />
  </div>
);
